<template>
  <div class="menu-links">
    <Loader :loading="isLoader" v-if="isLoader" />
    <ul v-else>
      <li
        v-for="(item, index) in items"
        :key="index"
        @click="navigateToPage(item.path)"
      >
        <router-link :to="item.path" v-if="(item.name !== 'Logout' && !disabled) && (item.name !== 'UAM' && !disabled)">
          <FormulationMappingSvgIcon
            v-if="item.name === 'Formulation Mapping'"
          />
          <ProductsSvgIcon v-if="item.name === 'Product Details'" />
          <ReportsSvgIcon v-if="item.name === 'Reports'" />

          <svg
            v-if="item.name === 'Additional Information'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
            />
          </svg>

          <svg
            class="fb-pm"
            v-if="item.name === 'Formulation Brand - Product Mapping'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M17 12h-6v4h1v4H8v-4h1v-4H3v4h1v4H0v-4h1v-4a2 2 0 0 1 2-2h6V6H7V0h6v6h-2v4h6a2 2 0 0 1 2 2v4h1v4h-4v-4h1v-4z"
            />
          </svg>

          <svg
            v-if="item.name === 'Comparison'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M6 6V2c0-1.1.9-2 2-2h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-4v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h4zm2 0h4a2 2 0 0 1 2 2v4h4V2H8v4zM2 8v10h10V8H2z"
            />
          </svg>

          <svg
            v-if="item.name === 'General Information'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
            />
          </svg>

          <svg
            v-if="item.name === 'Assigned Formulation/CUC'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M1 1h18v2H1V1zm0 8h18v2H1V9zm0 8h18v2H1v-2zM1 5h18v2H1V5zm0 8h18v2H1v-2z"
            />
          </svg>

          {{ item.name }}
        </router-link>

        <a
          v-if="item.name !== 'Logout' && disabled"
          href="javascript:void(0)"
          :disabled="disabled"
        >
          <svg
            v-if="item.name === 'Additional Information'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
            />
          </svg>

          <svg
            v-if="item.name === 'General Information'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
            />
          </svg>

          <svg
            v-if="item.name === 'Assigned Formulation/CUC'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M1 1h18v2H1V1zm0 8h18v2H1V9zm0 8h18v2H1v-2zM1 5h18v2H1V5zm0 8h18v2H1v-2z"
            />
          </svg>

          <svg
            class="fb-pm"
            v-if="item.name === 'Formulation Brand - Product Mapping'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M17 12h-6v4h1v4H8v-4h1v-4H3v4h1v4H0v-4h1v-4a2 2 0 0 1 2-2h6V6H7V0h6v6h-2v4h6a2 2 0 0 1 2 2v4h1v4h-4v-4h1v-4z"
            />
          </svg>

          <svg
            v-if="item.name === 'Comparison'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M6 6V2c0-1.1.9-2 2-2h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-4v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h4zm2 0h4a2 2 0 0 1 2 2v4h4V2H8v4zM2 8v10h10V8H2z"
            />
          </svg>

          {{ item.name }}
        </a>

      <!--UAM-->
        <router-link :to="item.path" 
          v-if="user.role === 'Admin' && item.name === 'UAM'"
        >
          <UamSvgIcon />
          {{ item.name }}
        </router-link>
      <!--logout -->
        <a
          v-if="item.name === 'Logout'"
          href="javascript:void(0)"
          @click="logout"
        >
          <LogoutSvgIcon />
          {{ item.name }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import authentication from "../../authentication";
export default {
  name: "Menu",
  components: {
    Loader: () => import(/* webpackPrefetch: true */ "../common/Loader"),
    FormulationMappingSvgIcon: () =>
      import(
        /* webpackPrefetch: true */ "../common/svg-icons/FormulationMappingIcon"
      ),
    ProductsSvgIcon: () =>
      import(/* webpackPrefetch: true */ "../common/svg-icons/ProductsIcon"),
    ReportsSvgIcon: () =>
      import(/* webpackPrefetch: true */ "../common/svg-icons/ReportsIcon"),
    LogoutSvgIcon: () =>
      import(/* webpackPrefetch: true */ "../common/svg-icons/LogoutIcon"),
    UamSvgIcon: () =>
      import(/* webpackPrefetch: true */ "../common/svg-icons/Uam"),
  },
  props: {
    items: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoader: true,
  }),
  created() {
    setTimeout(() => {
      this.isLoader = false;
    }, 500);
  },
  computed: {
    ...mapGetters({
      user: "GET_USER_ROLE",
    }),
  },
  methods: {
    ...mapMutations({ isMenuOpen: "SET_IS_MENU_OPEN" }),
    logout() {
      authentication.logout();
    },
    navigateToPage(path) {
      if (!this.disabled) {
        this.$router.push({ path: path }).catch(() => {});
        this.isMenuOpen(false);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.menu-links {
  position: relative;
  padding-top: 10px;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    li {
      a {
        padding: 1rem 0.7rem !important;
        font-size: 1.2rem;
        width: 230px;
        border-top: 1px solid #476f84;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        text-decoration: none;
        color: #1487c0;
        &:hover {
          background-color: #fff;
          color: #0d597f;
          svg {
            fill: #0d597f;
          }
        }
        &[disabled] {
          cursor: not-allowed;
        }
      }
      .router-link-active {
        background-color: #fff;
        &:hover {
          background-color: #fff;
        }
      }
      &:last-child a {
        border-bottom: 1px solid #476f84;
      }
    }
  }
}
*::-ms-backdrop,
.secondary-side__menu .menu-links ul li a {
  height: 50px;
}
*::-ms-backdrop,
.side-nav .menu-links ul li a {
  height: 43px;
}
.secondary-side__menu {
  background: #f0f8ff;
  margin: 10px 0px 10px 10px;
  .menu-links {
    ul {
      li {
        a {
          width: 190px;
          font-size: 1.1rem;
          border-right: 0px solid #ccc;
          border-top: 0px solid #476f84;
          color: #444;
          &:hover {
            color: #00acee;
            svg {
              fill: #00acee;
            }
          }
        }
        .router-link-active {
          background-color: #f7f7f7;
          border-right: 4px solid #00acee;
          color: #00acee;
          transition: all 500ms ease-in-out;
          &:hover {
            background-color: #fff;
            color: #00acee;
            svg {
              fill: #00acee;
            }
          }
          svg {
            fill: #00acee;
          }
        }
        &:last-child a {
          border-bottom: 0px solid #acd2fa;
        }
      }
    }
  }
}
</style>
