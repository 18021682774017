var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu-links" },
    [
      _vm.isLoader
        ? _c("Loader", { attrs: { loading: _vm.isLoader } })
        : _c(
            "ul",
            _vm._l(_vm.items, function(item, index) {
              return _c(
                "li",
                {
                  key: index,
                  on: {
                    click: function($event) {
                      return _vm.navigateToPage(item.path)
                    }
                  }
                },
                [
                  item.name !== "Logout" &&
                  !_vm.disabled &&
                  item.name !== "UAM" && !_vm.disabled
                    ? _c(
                        "router-link",
                        { attrs: { to: item.path } },
                        [
                          item.name === "Formulation Mapping"
                            ? _c("FormulationMappingSvgIcon")
                            : _vm._e(),
                          item.name === "Product Details"
                            ? _c("ProductsSvgIcon")
                            : _vm._e(),
                          item.name === "Reports"
                            ? _c("ReportsSvgIcon")
                            : _vm._e(),
                          item.name === "Additional Information"
                            ? _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 20 20"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          item.name === "Formulation Brand - Product Mapping"
                            ? _c(
                                "svg",
                                {
                                  staticClass: "fb-pm",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 20 20"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M17 12h-6v4h1v4H8v-4h1v-4H3v4h1v4H0v-4h1v-4a2 2 0 0 1 2-2h6V6H7V0h6v6h-2v4h6a2 2 0 0 1 2 2v4h1v4h-4v-4h1v-4z"
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          item.name === "Comparison"
                            ? _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 20 20"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M6 6V2c0-1.1.9-2 2-2h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-4v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h4zm2 0h4a2 2 0 0 1 2 2v4h4V2H8v4zM2 8v10h10V8H2z"
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          item.name === "General Information"
                            ? _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 20 20"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          item.name === "Assigned Formulation/CUC"
                            ? _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 20 20"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M1 1h18v2H1V1zm0 8h18v2H1V9zm0 8h18v2H1v-2zM1 5h18v2H1V5zm0 8h18v2H1v-2z"
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" " + _vm._s(item.name) + " ")
                        ],
                        1
                      )
                    : _vm._e(),
                  item.name !== "Logout" && _vm.disabled
                    ? _c(
                        "a",
                        {
                          attrs: {
                            href: "javascript:void(0)",
                            disabled: _vm.disabled
                          }
                        },
                        [
                          item.name === "Additional Information"
                            ? _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 20 20"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          item.name === "General Information"
                            ? _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 20 20"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          item.name === "Assigned Formulation/CUC"
                            ? _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 20 20"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M1 1h18v2H1V1zm0 8h18v2H1V9zm0 8h18v2H1v-2zM1 5h18v2H1V5zm0 8h18v2H1v-2z"
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          item.name === "Formulation Brand - Product Mapping"
                            ? _c(
                                "svg",
                                {
                                  staticClass: "fb-pm",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 20 20"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M17 12h-6v4h1v4H8v-4h1v-4H3v4h1v4H0v-4h1v-4a2 2 0 0 1 2-2h6V6H7V0h6v6h-2v4h6a2 2 0 0 1 2 2v4h1v4h-4v-4h1v-4z"
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          item.name === "Comparison"
                            ? _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 20 20"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M6 6V2c0-1.1.9-2 2-2h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-4v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h4zm2 0h4a2 2 0 0 1 2 2v4h4V2H8v4zM2 8v10h10V8H2z"
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" " + _vm._s(item.name) + " ")
                        ]
                      )
                    : _vm._e(),
                  _vm.user.role === "Admin" && item.name === "UAM"
                    ? _c(
                        "router-link",
                        { attrs: { to: item.path } },
                        [
                          _c("UamSvgIcon"),
                          _vm._v(" " + _vm._s(item.name) + " ")
                        ],
                        1
                      )
                    : _vm._e(),
                  item.name === "Logout"
                    ? _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: { click: _vm.logout }
                        },
                        [
                          _c("LogoutSvgIcon"),
                          _vm._v(" " + _vm._s(item.name) + " ")
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            0
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }